<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
    <div id="data-list-list-view" class="data-list-container">
      <data-view-sidebar
        :isSidebarActive="addNewDataSidebar"
        @closeSidebar="toggleDataSidebar"
        :data="sidebarData"
      />

      <vs-table
        ref="table"
        v-model="selected"
        pagination
        :max-items="itemsPerPage"
        search
        :data="Districts"
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse items-center flex-grow justify-between"
        >
          <vs-button type="border" icon-pack="feather" @click="addNewData">{{
            $t("AddNew")
          }}</vs-button>
          <!-- <vs-button type="border" icon-pack="feather" icon="icon-plus" @click="addNewData">Add New</vs-button> -->

          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
            <div
              class="
                p-4
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              "
            >
              <span class="mr-2"
                >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
                {{
                  Districts.length - currentPage * itemsPerPage > 0
                    ? currentPage * itemsPerPage
                    : Districts.length
                }}
                of {{ Districts.length }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!--  <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="itemsPerPage = 4">
                <span>4</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 10">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 15">
                <span>15</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 20">
                <span>20</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <template slot="thead">
          <vs-th sort-key="Districts">{{ $t("NameAr") }}</vs-th>
          <vs-th sort-key="Districts">{{ $t("NameEn") }}</vs-th>
          <vs-th sort-key="City">{{ $t("City") }} </vs-th>
          <vs-th>{{ $t("Actions") }}</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                {{tr.NameAr}}
              </vs-td>
              <vs-td>
                {{tr.NameEn}}
              </vs-td>
              <vs-td>
                {{tr.City.NameAr}}
              </vs-td>

              <vs-td class="whitespace-no-wrap">
                <feather-icon
                  icon="EditIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  @click.stop="editData(tr)"
                />
                <feather-icon
                  icon="TrashIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  @click.stop="openConfirm(tr)"
                />
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </div>
  </template>

  <script>
  import moduleDistrict from "@/store/settings/District/moduleDistrict.js";
  import DataViewSidebar from "./DataViewSidebar.vue";

  export default {
    components: {
      DataViewSidebar,
    },
    data() {
      return {
        selected: [],
        search: {},
        showSearchSidebar: false,
        itemsPerPage: 10,
        isMounted: false,
        // Data Sidebar
        addNewDataSidebar: false,
        sidebarData: {},
        rowDataForDelete: {},
        activeConfirm: false,
      };
    },

    computed: {
      currentPage() {
        if (this.isMounted) {
          return this.$refs.table.currentx;
        }
        return 0;
      },
      Districts() {
        return this.$store.state.DistrictList.Districts;
      },
    },

    methods: {
      addNewData() {
        this.sidebarData = {};
        this.toggleDataSidebar(true);
      },

      editData(data) {
        this.sidebarData = data;
        this.toggleDataSidebar(true);
      },
      deleteData(data) {
        debugger;
        this.$store
          .dispatch("DistrictList/removeItem", data)
          .then(() => {
            this.$store
              .dispatch("DistrictList/fetchDataListItems")
              .then(() => {
                this.$vs.loading.close();
              });
          })
          .catch(() => {});
      },

      toggleDataSidebar(val = false) {
        this.addNewDataSidebar = val;
      },
      openConfirm(data) {
        this.rowDataForDelete = data;
        this.$vs.dialog({
          type: "confirm",
          color: "danger",
          title: this.$t("Confirm"),
          text: this.$t("Areyousureyouwantdeletethisrecord"),
          accept: this.acceptAlert,
        });
      },
      toggleSearchSidebar(val) {
        this.showSearchSidebar = val;
      },

      acceptAlert() {
        this.deleteData(this.rowDataForDelete);
        this.$vs.notify({
          color: "danger",
          title: this.$t("Deletedrecord"),
          text: this.$t("The selected record was successfully deleted"),
        });
      },
    },

    created() {
      this.$vs.loading();
      if (!moduleDistrict.isRegistered) {
        this.$store.registerModule("DistrictList", moduleDistrict);
        moduleDistrict.isRegistered = true;
      }

      this.$store
        .dispatch("DistrictList/fetchDataListItems")
        .then(() => {
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
          window.showError();
        });
    },

    mounted() {
      this.isMounted = true;
    },
  };
  </script>

  <style lang="scss">
  #data-list-list-view {
    .vs-con-table {
      .product-name {
        max-width: 23rem;
      }

      .vs-table--header {
        display: flex;
        flex-wrap: wrap-reverse;
        margin-left: 1.5rem;
        margin-right: 1.5rem;

        > span {
          display: flex;
          flex-grow: 1;
        }

        .vs-table--search {
          padding-top: 0;

          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;

            & + i {
              left: 1rem;
            }

            &:focus + i {
              left: 1rem;
            }
          }
        }
      }

      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;

        tr {
          box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

          td {
            padding: 20px;

            &:first-child {
              border-top-left-radius: 0.5rem;
              border-bottom-left-radius: 0.5rem;
            }

            &:last-child {
              border-top-right-radius: 0.5rem;
              border-bottom-right-radius: 0.5rem;
            }

            .product-img {
              height: 70px;
              width: 100px;
            }
          }

          td.td-check {
            padding: 20px !important;
          }
        }
      }

      .vs-table--thead {
        th {
          padding-top: 0;
          padding-bottom: 0;

          .vs-table-text {
            text-transform: uppercase;
            font-weight: 600;
          }
        }

        th.td-check {
          padding: 0 15px !important;
        }

        tr {
          background: none;
          box-shadow: none;
        }
      }

      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
  </style>
