
export default {
  ADD_ITEM(state, item) {
    state.Districts.unshift(item);
  },
  SET_Districts(state, Districts) {
    state.Districts = Districts;
  },
  UPDATE_District(state, District) {
    const DistrictIndex = state.Districts.findIndex(
      p => p.ID == District.ID
    );
    if (DistrictIndex != -1) {
      Object.assign(state.Districts[DistrictIndex], District);
    }
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.Districts.findIndex(p => p.ID == itemId);
    state.Districts.splice(ItemIndex, 1);
  }
};
