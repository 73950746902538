
import axios from "@/axios.js"

export default {
  addItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      debugger;
      axios.post("api/Country/AddDistrict", item)
        .then((response) => {
          commit('ADD_ITEM', Object.assign(response.data.data, { ID: response.data.data.ID }))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchDataListItems({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("api/Country/GetAllDistricts")
        .then((response) => {
          commit('SET_Districts', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  updateItem({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios.put("api/Country/UpdateDistrict", item)
        .then((response) => {
          commit('UPDATE_District', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetItemByID({ commit }, itemid) {
    return new Promise((resolve, reject) => {
      axios.get("api/Country/GetDistrict?ID="+ itemid)
        .then((response) => {
          commit('UPDATE_District', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  removeItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.delete("api/Country/DeleteDistrict?ID="+item.ID)
        .then((response) => {
          commit('REMOVE_ITEM', item.ID)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
